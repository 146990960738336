/* STORE PARA GESTIONAR LOS COBROS */

import { cobrosService } from "@/_services";

const state = {
  cobros: {
    loading: false,
    data: null
  }
};
const mutations = {
  SET_COBROS_STATUS(state, status) {
    state.cobros.loading = status;
  },
  SET_COBROS_DATA(state, data) {
    state.cobros.data = data;
    state.cobros.loading = false;
  }
};
const actions = {
  
  setCobrosAll({ commit }) {
   
    const promesa = new Promise((resolve, reject) => {      
      commit("SET_COBROS_STATUS", true);      
      cobrosService.cobrosAll().then( res  => {        
        commit("SET_COBROS_DATA", res.data);
        return resolve(res.data);
      });

    })

    return promesa;
  }

};
const getters = {};

export const cobros = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
