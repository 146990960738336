/* STORE PARA GESTIONAR LOS AVISOS */

import { avisosService } from "@/_services";

const state = {
  avisos: {
    loading: false,
    data: null
  },
  avisosPasadosUsuario: {
    loading: false,
    data: null
  },
  usuarios: []
};

const mutations = {
  SET_AVISOS_STATUS(state, status) {
    state.avisos.loading = status;
  },
  SET_AVISOS_DATA(state, data) {
    state.avisos.data = data;
    state.avisos.loading = false;
  },
  SET_AVISOSPASADOSUSUARIO_STATUS(state, status) {
    state.avisosPasadosUsuario.loading = status;
  },
  SET_AVISOSPASADOSUSUARIO_DATA(state, data) {
    state.avisosPasadosUsuario.data = data;
    state.avisosPasadosUsuario.loading = false;
  },
  SET_USUARIOS(state, data) {
    state.usuarios = data;
  }
};

const actions = {
  setAvisosAll({ commit }) {
    const promesa = new Promise((resolve, reject) => {
      commit("SET_AVISOS_STATUS", true);
      avisosService.avisosAll().then(res => {
        commit("SET_AVISOS_DATA", res.data);
        return resolve(res.data);
      });
    });
    return promesa;
  },
  setAvisosPasadosUsuario({ commit }, usuario) {
    const promesa = new Promise((resolve, reject) => {
      commit("SET_AVISOSPASADOSUSUARIO_STATUS", true);
      avisosService.avisosPasadosUsuario(usuario).then(res => {
        commit("SET_AVISOSPASADOSUSUARIO_DATA", res.data);
        return resolve(res.data);
      });
    });
    return promesa;
  },
  setUsuarios({ commit }) {
    const promesa = new Promise((resolve, reject) => {
      avisosService.usuarios().then(res => {
        commit("SET_USUARIOS", res.data);
        return resolve(res.data);
      });
    });
    return promesa;
  },
};

const getters = {};

export const avisos = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
