<template>
  <div class="pa-0 ma-0">
    <top-main></top-main>
    <!-- topbar -->
    <v-main fluid class="fill-height">
      <router-view></router-view>
    </v-main>
    <footer-main></footer-main>
    <!-- overlay cargando -->
    <v-overlay :value="getterMainLoader.loading">
      <v-progress-circular indeterminate size="84"></v-progress-circular>
    </v-overlay>
    <!-- snackbar general -->
    <v-snackbar
      class="snackPos"
      :top="true"
      :right="true"
      :color="snackbar.color"
      v-model="snackbar.mostrar"
      :timeout="snackbar.timeout || 2000"
      centered
    >
      {{ snackbar.texto }}
      <!--  <v-btn outlined text @click="snackbar.mostrar = false">
        Cerrar
      </v-btn> -->
    </v-snackbar>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from "vuex";
import TopMain from "@/components/parts/top/TopMain";
import FooterMain from "@/components/parts/footer/FooterMain";

export default {
  name: "home",
  data: () => ({
    snackbar: {
      mostrar: false,
      texto: null,
      color: "primary"
    }
  }),
  components: {
    TopMain,
    FooterMain
  },
  computed: {
    ...mapGetters("comun", ["getterMainLoader", "getterSnackbar"])
  },
  watch: {
    getterSnackbar: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        this.snackbar = this.getterSnackbar;
      }
    }
  },
  mounted() {
    this.$vuetify.goTo(0);
  }
};
</script>
<style>
.snackPos {
  padding-right: 55px !important;
  padding-top: 75px !important;
}
</style>
