export function handleResponse(response) {
  console.log("desde handle reponse", response);

  return response.text().then(text => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      if ([401, 403].indexOf(response.status) !== -1) {
        // auto logout if 401 response returned from api
        console.log("hacemos logout desde el handle response");
        logout();
        location.reload();
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

function logout() {
  // console.log("eliminamos el token");
  // remove user from local storage to log user out
  localStorage.removeItem("user");
}
