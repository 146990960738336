/* eslint-disable no-unused-vars */
const localStName = process.env.VUE_APP_LOCALST;
import { userSrv } from "@/_services";

let usuarioDatos = JSON.parse(localStorage.getItem(localStName)) || null;

const state = {
  usuario: {
    data: usuarioDatos,
    loading: false
  }
};
const mutations = {
  SET_REMOVE_USER_STORAGE() {
    localStorage.removeItem(localStName);
  },
  SET_USER_STORAGE(state, datos) {
    state.usuario.data = datos;
    const data = JSON.stringify(datos);
    localStorage.setItem(localStName, data);
  }
};
const actions = {
  /* funcion para guardar los datos asincronamente en el localStorage */
  async setUserDataLocalStorage({ commit }, datos) {
    const data = await JSON.stringify(datos);
    await localStorage.setItem(localStName, data);
    return true;
  },
  /* funcion para logear al usuario */
  setLoginUser({ commit }, datos) {
    const promesa = new Promise(resolve => {
      userSrv
        .login(datos)
        .then(async res => {
          //commit("SET_RECORD_BYID", res.data);
          if (res.status === 200) {
            commit("SET_USER_STORAGE", res.data);
            setTimeout(() => {
              return resolve(true);
            }, 400);
          }

          if (res.hasOwnProperty("response") && res.response.status === 401) {
            commit("SET_REMOVE_USER_STORAGE");
            // console.log("no estas autorizado");
            return resolve(false);
          }
          // console.log(res);
          return resolve(res);
        })
        .catch(err => {
          const e = err;
          // console.log(e, typeof e, e.message);
          // tenemos un error de tipo respuesta (no hay conexion api)
          if (e.message === "err.respuesta") {
            const snackData = setSnackBar(
              "No se ha podido conectar con el servidor",
              "orange"
            );
            // console.log(snackData);
            const root = { root: true };
            commit("comun/SET_SNACK_BAR", snackData, root);
          }
          // console.log("error de respuesta");
        });
    });
    return promesa;
  },

  setLogOut({ commit }, datos) {
    return new Promise((resolve, reject) => {
      localStorage.removeItem(localStName);
      return resolve(true);
    });
  }
};

const getters = {
  gtrUserData: state => {
    return state.usuario.data.user;
  }
};

const setSnackBar = (texto, color) => {
  return {
    mostrar: true,
    texto,
    color,
    top: true,
    right: true,
    timeout: 1000
  };
};

export const user = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
