import Vue from "vue";
import Vuex from "vuex";
import { user } from "./user.module";
import { records } from "./records.module";
import { comun } from "./comun.module";
import { cobros } from "./cobros.module";
import { avisos } from "./avisos.module";
import { contratos } from "./contratos.module";
import { especialidades } from "./especialidades.module";
import { guias } from "./guias.module";
import { agrup } from "./agrup.module";
Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    records,
    user,
    comun,
    cobros,
    avisos,
    contratos,
    especialidades,
    guias,
    agrup
  },
  strict: true
});
