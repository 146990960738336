/* PARA GESTIONAR LOS RECORDS -  FICHAS */

import { recordsService } from "@/_services";

const state = {
  recordAll: {
    loading: false,
    data: null,
  },
  recordById: {
    loading: false,
    data: null,
  },
  recordCreate: {
    loading: false,
    data: null,
  },
  recordUpdate: {
    loading: false,
    data: null,
  },
  /* variables para el control de filtros de la tabla fichas */
  recordFilter: {
    name: null,
    address: null,
    phone: null,
    status: null,
    creationdateIni: null,
    creationdateEnd: null,
    specialties: null,
    subCategorias: null,
    catNombres: null,
    espNombres: null,
  },
  recordPagination: {
    groupBy: [],
    groupDesc: [],
    itemsPerPage: 15,
    multiSort: true,
    mustSort: false,
    page: 1,
    sortBy: [],
    sortDesc: [],
  },
  recordsReload: false,
};
const mutations = {
  /* mutation para actualizar los datos en local al eliminar una ficha */
  SET_RECORD_RECORD: (state, value) => {
    state.recordAll.records = value;
  },

  SET_RECORD_ALL: (state, value) => {
    state.recordAll = value;
  },

  SET_RECORD_BYID: (state, value) => {
    state.recordById = value;
  },

  SET_RECORD_CREATE: (state, value) => {
    state.recordCreate = value;
  },

  SET_RECORD_UPDATE: (state, value) => {
    state.recordUpdate = value;
  },

  // actualizar la lista de adjuntos de un registro
  SET_RECORD_ADJ: (state, value) => {
    state.recordById.record.record_files = value;
  },

  // seteamos el filtro actual
  SET_RECORD_FILTER: (state, value) => {
    state.recordFilter = value;
  },
  // seteamos si queremos que recargue la página
  SET_RECORDS_RELOAD: (state, value) => {
    state.recordsReload = value;
  },
  // seteamos los datos de la paginación actual
  SET_RECORD_PAGINATION: (state, value) => {
    if (!value) {
      state.recordPagination = {
        groupBy: [],
        groupDesc: [],
        itemsPerPage: 15,
        multiSort: true,
        mustSort: false,
        page: 1,
        sortBy: [],
        sortDesc: [],
      };
    } else {
      state.recordPagination = value;
    }
  },

  /*   SET_STATE: (state, status) => {
      //ejemplo commit('recordUpdate) <- tengo que probarlor
      state[status.prop] = status.value;
    } */
};
const actions = {
  /* RECUPERAR LA LISTA DE LOS REGISTROS */
  setRecordAll({ commit, dispatch }) {
    const promesa = new Promise((resolve, reject) => {
      recordsService.recordAll().then((res) => {
        // comprobamos si tenemos un no autorizado con -1
        if (res === -1) {
          dispatch(
            "user/setLogOut",
            {
              a: 1,
            },
            {
              root: true,
            }
          );
          return;
        }
        commit("SET_RECORD_ALL", res.data);
        return resolve(res.data);
      });
    });

    return promesa;
  },
  /* RECUPERA UN REGISTRO PARTICULAR POR SU ID */
  setRecordById({ commit }, id) {
    const promesa = new Promise((resolve, reject) => {
      recordsService.recordById(id).then((res) => {
        commit("SET_RECORD_BYID", res.data);
        return resolve(res.data);
      });
    });
    return promesa;
  },

  /* CREAR UN NUEVO REGISTRO */
  setRecordCreate({ commit }, data) {
    return new Promise((resolve, reject) => {
      recordsService.recordCreate(data).then((res) => {
        return resolve(res.data);
      });
    });
  },

  /* ACTUALIZAR UN REGISTRO EXISTENTE */
  setRecordUpdate({ dispatch, commit }, data) {
    return new Promise((resolve, reject) => {
      recordsService.recordUpdate(data).then((res) => {
        // dispatch("setRecordById", res.data.reco_id);
        commit("SET_RECORD_BYID", res.data);
        return resolve(res.data);
      });
    });
  },

  /* CLONAR UNA FICHA EXISTENTE */
  setRecordClone({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      recordsService.recordClone(data).then((res) => {
        return resolve(res.data);
      });
    });
  },

  /* ELIMINAR UNA FICHA EXISTENTE */
  async setRecordDelete({ commit }, id) {
    try {
      let res = await recordsService.recordDelete(id);
      // commit("SET_RECORD_ADJ", res.data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  /* RECOGER LISTA DE ZIPCODES */
  setZipCodesSearch({}, filtro) {
    return new Promise((resolve, reject) => {
      recordsService.zipCodesSearch(filtro).then((res) => {
        return resolve(res.data);
      });
    });
  },

  /* RECOGER TODA LA LISTA DE ZIPCODES */
  setZipCodesAll({}) {
    return new Promise((resolve, reject) => {
      recordsService.zipCodesAll().then((res) => {
        return resolve(res.data);
      });
    });
  },

  /* RECOGER TODA LA LISTA DE CCAA PROVINCIAS */
  setProvCCAAsAll({}) {
    return new Promise((resolve, reject) => {
      recordsService.provCCAAsAll().then((res) => {
        return resolve(res.data);
      });
    });
  },

  /* CREAR UNA DIRECCIÓN NUEVA */
  setRecordAddressNew({ dispatch }, datos) {
    const promesa = new Promise((resolve, reject) => {
      recordsService.recordAddressNew(datos).then(async (res) => {
        await dispatch("setRecordById", res.data.read_idrecord);
        return resolve(true);
      });
    });
    return promesa;
  },

  /* EDITAR UNA DIRECCIÓN */
  setRecordAddressUpdate({ commit, dispatch }, datos) {
    return new Promise((resolve, reject) => {
      recordsService
        .recordAddressUpdate(datos)
        .then( async (res) => {
          await dispatch("setRecordById", res.data.read_idrecord);
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },

  /* ELIMINAR UNA DIRECCIÓN */
  setRecordAddressDelete({ commit, dispatch }, id) {
    return new Promise((resolve, reject) => {
      recordsService
        .recordAddressDelete(id)
        .then((res) => {
          // dispatch("setRecordById", res.data.read_idrecord);
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  /* CREAR UN AVISO NUEVO */
  setRecordNotiNew({ dispatch }, datos) {
    const promesa = new Promise((resolve, reject) => {
      recordsService.recordNotiNew(datos).then(async (res) => {
        await dispatch("setRecordById", res.data.reno_idrecord);
        return resolve(true);
      });
    });
    return promesa;
  },

  /* EDITAR UN AVISO */
  setRecordNotiUpdate({ commit, dispatch }, datos) {
    return new Promise((resolve, reject) => {
      recordsService
        .recordNotiUpdate(datos)
        .then(async (res) => {
          await dispatch("setRecordById", res.data.reno_idrecord);
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },

  /* ELIMINAR UN AVISO */
  setRecordNotiDelete({ commit, dispatch }, id) {
    return new Promise((resolve, reject) => {
      recordsService
        .recordNotiDelete(id)
        .then(async (res) => {
          await dispatch("setRecordById", res.data.reno_idrecord);
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },

  /* SECCIÓN DE LAS ESPECIALIDADES */
  /* CREAR UN AVISO NUEVO */
  setSpecialtiesNew({ dispatch }, datos) {
    const promesa = new Promise((resolve, reject) => {
      recordsService.recordSpecNew(datos).then((res) => {
        dispatch("setRecordById", res.data.resp_idrecord);
        return resolve(true);
      });
    });
    return promesa;
  },

  /* EDITAR UN AVISO */
  setSpecialtiesUpdate({ commit, dispatch }, datos) {
    return new Promise((resolve, reject) => {
      recordsService
        .recordSpecUpdate(datos)
        .then((res) => {
          dispatch("setRecordById", res.data.resp_idrecord);
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },

  /* ELIMINAR UN AVISO */
  setSpecialtiesDelete({ commit, dispatch }, id) {
    return new Promise((resolve, reject) => {
      recordsService
        .recordSpecDelete(id)
        .then((res) => {
          // dispatch("setRecordById", res.data.resp_idrecord);
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  /* funcion para recuperar todos los estados de una ficha - para el new */
  setStatusesAll({ commit }) {
    return new Promise((resolve, reject) => {
      recordsService
        .statusesAll()
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },

  /* funcion para descargar un adjunto de las fichas */
  async setDownloadAttachment({ commit }, id) {
    const data = await recordsService.downloadAttachment(id);
    return data;
  },

  /* funcion para eliminar un adjunto de las fichas */
  async setDeleteAttachment({ commit }, id) {
    const data = await recordsService.deleteAttachment(id);
    return data;
  },
  /* funcion para crear un comentario en multiples esp */
  async setCrearComMultiple({ dispatch }, data) {
    const datos = await recordsService.crearComMultiple(data);
    dispatch("setRecordById", data.reco_id);

    return datos;
  },
};
const getters = {
  /* LISTA TODOS LOS REGISTROS */
  recordAll: (state) => {
    return state.recordAll;
  },
  recordById: (state) => {
    return state.recordById;
  },
  // filtro actual de la pestaña de busqueda fichas
  recordFilter: (state) => {
    return state.recordFilter;
  },

  // comprobar si hay que recargar datos de la api al actualizar una ficha
  recordsReload: (state) => {
    return state.recordsReload;
  },

  // getter paginación actual de la tabla Fichas
  recordPagination: (state) => {
    return state.recordPagination;
  },
};

export const records = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
