import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from 'vue-meta';
import Home from "../views/Home.vue";
const localStName = process.env.VUE_APP_LOCALST;
const publicPages = ["/login"];

const rutasBaseRol = [
  {
    role: 1,
    rolName: "user",
    rutaDefecto: "/fichas",
  },
  {
    role: 2,
    rolName: "cobros",
    rutaDefecto: "/cobros",
  },
  {
    role: 3,
    rolName: "admin",
    rutaDefecto: "/fichas",
  },
];
Vue.use(VueRouter);
Vue.use(VueMeta);

const routes = [
  {
    path: "/",
    component: Home,
    children: [
      {
        name: "fichas",
        path: "",
        component: () =>
          import(/* webpackChunkName: "fichas" */ "@/components/fichas/Fichas"),
        meta: {
          role: [1, 3],
        },
      },
      {
        name: "especialidades",
        path: "/especialidades",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "especialidades" */
            "@/components/especialidades/Especialidades.vue"
          ),
        meta: {
          role: [1, 3],
        },
      },
      {
        name: "fdetalle",
        path: "/fdetalle/:id",
        component: () =>
          import(
            /* webpackChunkName: "fdetalle" */
            "@/components/fichas/FichaDetalle.vue"
          ),
        meta: {
          role: [1, 3],
        },
      },
      {
        name: "fnuevo",
        path: "/fnew",
        component: () =>
          import(
            /* webpackChunkName: "fnuevo" */
            "@/components/fichas/parts/forms/FichaNew.vue"
          ),
        meta: {
          role: [1, 3],
        },
      },
      {
        name: "cobros",
        path: "/cobros",
        component: () =>
          import(
            /* webpackChunkName: "cobros" */
            "@/components/cobros/Cobros.vue"
          ),
        meta: {
          role: [2, 3],
        },
      },
      {
        name: "avisos",
        path: "/avisos",
        component: () =>
          import(
            /* webpackChunkName: "avisos" */
            "@/components/avisos/Avisos.vue"
          ),
        meta: {
          role: [1, 3],
        },
      },
      {
        name: "contratos",
        path: "/contratos",
        component: () =>
          import(
            /* webpackChunkName: "contratos" */
            "@/components/contratos/Contratos.vue"
          ),
        meta: {
          role: [1, 3],
        },
      },
      {
        name: "guias",
        path: "/guias",
        component: () =>
          import(
            /* webpackChunkName: "guias" */ "@/components/guias/Guias.vue"
          ),
        meta: {
          role: [1, 3],
        },
      },
      {
        name: "gnew",
        path: "/gnew",
        component: () =>
          import(
            /* webpackChunkName: "gnew" */
            "@/components/guias/forms/GuiaForm.vue"
          ),
        meta: {
          role: [1, 3],
        },
      },
      {
        name: "gdetalle",
        path: "/gdetalle/:id",
        component: () =>
          import(
            /* webpackChunkName: "gdetalle" */
            "@/components/guias/forms/GuiaEditForm.vue"
          ),
        meta: {
          role: [1, 3],
        },
      },
      {
        name: "anew",
        path: "/anew",
        component: () =>
          import(
            /* webpackChunkName: "anew" */
            "@/components/agrupaciones/forms/AgrupacionesForm.vue"
          ),
        meta: {
          role: [1, 3],
        },
      },
      {
        name: "adetalle",
        path: "/adetalle/:id",
        component: () =>
          import(
            /* webpackChunkName: "gdetalle" */
            "@/components/agrupaciones/forms/AgrupacionesEditForm.vue"
          ),
        meta: {
          role: [1, 3],
        },
      },
    ],
  },
  {
    path: "/login",
    component: () => import(/* webpackChunkName: "login" */ "@/views/Login"),
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach(async (to, from, next) => {
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = JSON.parse(localStorage.getItem(localStName)) || false;

  if (authRequired && !loggedIn) {
    return next("login");
  }

  if (!Object(loggedIn.user).hasOwnProperty("role")) {
    await eliminarDatos(localStName);
    return next();
  }

  // comprobación de la ruta dependiendo del rol del usuario
  const userRole = loggedIn.user.role;
  const rutaRole = to.meta.role;
  const rutaPermitida = rutaRole.some((x) => x === userRole);
  const baseRol = rutasBaseRol.find((r) => r.role === userRole);

  if (!rutaPermitida) {
    return next({ path: baseRol.rutaDefecto });
  }

  // Add tiny timeout to finish page transition
  setTimeout(() => next(), 30);
});

const eliminarDatos = (localStName) => {
  localStorage.removeItem(localStName);
  return true;
};

export default router;
