/* STORE PARA GESTIONAR LOS COBROS */

import { contratosService } from "@/_services";

const state = {
  contratos: {
    loading: false,
    data: null
  }
};
const mutations = {
  SET_CONTRATOS_STATUS(state, status) {
    state.contratos.loading = status;
  },
  SET_CONTRATOS_DATA(state, data) {
    state.contratos.data = data;
    state.contratos.loading = false;
  }
};
const actions = {
  
  setContratosAll({ commit }) {
   
    const promesa = new Promise((resolve, reject) => {      
      commit("SET_CONTRATOS_STATUS", true);      
      contratosService.contratosAll().then( res  => {        
        commit("SET_CONTRATOS_DATA", res.data);
        return resolve(res.data);
      });

    })

    return promesa;
  }

};
const getters = {};

export const contratos = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
