import { guiasSvr } from "@/_services";

const state = {
  stGuias: null,
  stGuiaById: null,
  stZipcodes: null,
  stTipoGuia: null,
  stProvsCCAA: null
};
const mutations = {
  /* funcion para setear los datos de guiasAll */
  SET_GUIAS_ALL: (state, value) => {
    state.stGuias = value.datos;
    state.stZipcodes = value.zipcodes;
    state.stTipoGuia = value.tipoGuia;
    state.stProvsCCAA = value.provsCCAA;
  },

  /* function para setear una guia */
  SET_GUIA_BYID: (state, value) => {
    state.stGuiaById = value.datos;
    state.stZipcodes = value.zipcodes;
    state.stTipoGuia = value.tipoGuia;
    state.stProvsCCAA = value.provsCCAA;
  }
};
const actions = {
  /* funcion para recoger todas las guias */
  async setGuiasAll({ commit }) {
    const salida = await guiasSvr.guiasAll();
    commit("SET_GUIAS_ALL", salida.data);
    return salida.data;
  },

  /* funcion para recuperar una guia */
  async setGuiaById({ commit }, id) {
    const salida = await guiasSvr.guiaById(id);
    commit("SET_GUIA_BYID", salida.data);
    return salida.data;
  },

  /*  funcion para actualizar una guia */
  async setGuiaUpdate({ commit }, data) {
    const { datos, id } = data;
    const salida = await guiasSvr.guiaUpdate(datos, id);
    return salida.data;
  },

  /* funcion para recoger los datos de una guia al entrar en el form ,
        tabla de provincias_ccmm, los zip_codes y tipo_guia
    */
  async setDatosGuias() {
    const salida = await guiasSvr.datosGuias();
    return salida.data;
  },

  /* funcion para guardar una nueva guia */
  async setNuevaGuia({ commit }, datos) {
    const salida = await guiasSvr.nuevaGuia(datos);
    return salida.data;
  },

  /*  funcion para recuperar el pdf guia */
  async setGuiaPdf({ commit }, id) {
    const res = await guiasSvr.guiaPdf(id);
    return res;
  }
};
const getters = {
  gtrGuiasAll: state => _.cloneDeep(state.stGuias),
  gtrGuiaById: state => _.cloneDeep(state.stGuiaById),
  gtrZipCodes: state => _.cloneDeep(state.stZipcodes),
  gtrTipoGuia: state => _.cloneDeep(state.stTipoGuia),
  gtrProvsCCAA: state => _.cloneDeep(state.stProvsCCAA)
};

export const guias = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
