import { cloneDeep } from "lodash";
const state = {
  mainLoader: {
    loading: false
  },
  snackbar: {
    mostrar: false,
    texto: "",
    color: "primary",
    top: true,
    right: true,
    timeout: 1000
  }
};
const mutations = {
  SET_MAIN_LOADER(state, status) {
    state.mainLoader.loading = status;
  },
  SET_SNACK_BAR(state, snack) {
    state.snackbar = snack;
  }
};
const actions = {
  setSnackBar({ commit }, datos) {
    commit("SET_SNACK_BAR", cloneDeep(datos));
  }
};
const getters = {
  getterSnackbar: state => JSON.parse(JSON.stringify(state.snackbar)),
  getterMainLoader: state => state.mainLoader
};

export const comun = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
