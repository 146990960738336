let config;

//middleware para los servicios

const ruta =
  process.env.NODE_ENV === "production"
    ? process.env.VUE_APP_PRODUCTION_API
    : process.env.VUE_APP_DEV_API;

if (process.env.NODE_ENV === "production") {
  config = {
    apiUrl: ruta,
    timeoutDuration: 1000,
    someOtherProps: "abc"
  };
} else {
  config = {
    apiUrl: ruta,
    timeoutDuration: 1000,
    someOtherProps: "abc"
  };
}

export { config };
