<template>
  <v-footer color="indigo" active app>
    <div class="white--text">{{ compVersion }}</div>
    <v-spacer></v-spacer>
    <div class="white--text" v-html="texto">
    </div>
  </v-footer>
</template>

<script>
export default {
  name: "footer-main",
  computed: {
    compVersion() {
      return process.env.VUE_APP_VERSION
        ? "v " + process.env.VUE_APP_VERSION
        : "";
    },
    texto(){
      return 'Seguros Atocha &copy; '+new Date().getFullYear();
    }
  }
};
</script>

<style scoped></style>
