import { config } from "@/_config";

// import axios from "axios";
import { authHeader } from "@/_helpers";

export const avisosService = {
  avisosAll,
  avisosPasadosUsuario,
  usuarios
};

function avisosAll() {
  const url = `${config.apiUrl}/avisos`;
  const miConfig = {
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    }
  };

  return axios
    .get(url, miConfig)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
}

function avisosPasadosUsuario(usuario) {
  const url = `${config.apiUrl}/avisosPasadosUsuario/${usuario}`;
  const miConfig = {
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    }
  };

  return axios
      .get(url, miConfig)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
}

function usuarios() {
  const url = `${config.apiUrl}/usuarios`;
  const miConfig = {
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    }
  };

  return axios
      .get(url, miConfig)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
}
