<template>
  <v-app-bar app color="indigo" dark>
    <v-toolbar-title>ATOCHA - Usuario conectado: {{ gtrUserData.name }}</v-toolbar-title>

    <v-spacer></v-spacer>
    <span v-if="!isLoading">
      <router-link
        v-for="(ruta, n) in compMenu"
        :key="n"
        tag="a"
        :class="btnClass"
        :to="ruta.ruta"
        :exact-active-class="'v-btn--active'"
        >
        <v-badge
            color="red"
            :value="countAvisosPasadosUsuario>0 && ruta.nombre==='Avisos' ? true : false">
          <template v-slot:badge>
            {{ countAvisosPasadosUsuario }}
          </template>
          {{ ruta.nombre }}
        </v-badge>
      </router-link>
      <!--  <v-btn
        :to="`/${ruta.ruta}`"
        text
        v-for="(ruta, n) in compMenu"
        :key="n"
        class="ml-1"
      >
        <span class="mr-2">{{ ruta.nombre }}</span>
      </v-btn> -->
    </span>
    <v-btn @click="salir" text class="ml-1">
      <span class="mr-2">salir</span>
    </v-btn>
  </v-app-bar>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: "TopMain",
  data: () => ({
    btnClass:
      "ml-1 v-btn v-btn--flat v-btn--router v-btn--text theme--dark v-size--default",
    isLoading: true,
    menu: [
      { nombre: "Fichas", ruta: "/", role: [1, 3] },
      { nombre: "Avisos", ruta: "/avisos", role: [1, 3] },
      { nombre: "Contratos", ruta: "/contratos", role: [1, 3] },
      { nombre: "Especialidades", ruta: "/especialidades", role: [1, 3] },
      { nombre: "Cobros", ruta: "/cobros", role: [2, 3] },
      { nombre: "Guias", ruta: "/guias", role: [1, 3] },
    ],
    rolUser: 2
  }),
  methods: {
    ...mapActions("user", ["setLogOut"]),
    ...mapActions("avisos", ["setAvisosPasadosUsuario"]),
    salir() {
      this.setLogOut(true).then(() => {
        this.$router.push("/login");
      });
    },
    cargarAvisosPasados(){
      this.setAvisosPasadosUsuario(this.gtrUserData.id);
    }
  },
  computed: {
    ...mapGetters("user", ["gtrUserData"]),
    ...mapState("avisos", ["avisosPasadosUsuario"]),
    /* menu adaptado al rol de usuario */
    compMenu() {
      return this.menu.filter((m) => m.role.indexOf(this.compUserRole) > -1);
    },
    compUserRole() {
      if (this.gtrUserData) {
        return this.gtrUserData.role;
      } else {
        return 1;
      }
    },
    countAvisosPasadosUsuario() {
      return this.avisosPasadosUsuario.data==null ? 0 : this.avisosPasadosUsuario.data.length;
    },
  },
  async mounted() {
    this.cargarAvisosPasados();
    // this.rolUser = (await this.gtrUserData.role) || 1;
    setTimeout(() => {
      this.isLoading = false;
    }, 500);
  },
};
</script>

<style scoped></style>
