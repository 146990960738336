export function authHeader() {
  const localStName = process.env.VUE_APP_LOCALST;
  // return authorization header with jwt token
  let user = JSON.parse(localStorage.getItem(localStName));

  if (user && user.access_token) {
    return { Authorization: "Bearer " + user.access_token };
  } else {
    return {};
  }
}
