import { config } from "@/_config";
import { authHeader } from "@/_helpers";

// import axios from "axios";

export const contratosService = {
  contratosAll
};

function contratosAll() {
  const url = `${config.apiUrl}/contratos`;
  const miConfig = {
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    }
  };

  return axios
    .get(url, miConfig)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
}
