<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>
<script>
/* componentes */

export default {
  name: "App",
  metaInfo: {
    title: 'Login',
    titleTemplate: 'Atocha Insidenet - %s'
  },

  components: {},

  data: () => ({
    //
  }),
};
</script>
<style scoped>
.router-link-active {
  color: red;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  transition: opacity 1s ease;
}

.fade-leave {
}

.fade-leave-active {
  transition: opacity 1s ease;
  opacity: 0;
}
</style>
