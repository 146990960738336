import { config } from "@/_config";

// import axios from "axios";
import { authHeader } from "@/_helpers";

export const agrupSvr = {
  agrupAll,
  agrupById,
  agrupUpdate,
  datosAgrup,
  nuevaAgrup
};

/* funcion para solicitar todas las guias */
function agrupAll() {
  const url = `${config.apiUrl}/agrup`;
  const miConfig = {
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    }
  };

  return axios
    .get(url, miConfig)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
}

/* funcion para solicitar una guia por su id */
function agrupById(id) {
  const url = `${config.apiUrl}/agrup/${id}`;
  const miConfig = {
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    }
  };
  return axios
    .get(url, miConfig)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
}
/* funcion para actualizar una guia */
function agrupUpdate(datos, id) {
  const url = `${config.apiUrl}/agrup/${id}`;
  const miConfig = {
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    }
  };
  return axios
    .put(url, datos, miConfig)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
}

/* funcion para solicitar los datos necesarios para gestionar una guia */
function datosAgrup() {
  const url = `${config.apiUrl}/datosAgrup`;
  const miConfig = {
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    }
  };

  return axios
    .get(url, miConfig)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
}

/* funcion para dar de alta una nueva guia */
function nuevaAgrup(data) {
  const url = `${config.apiUrl}/agrup`;
  const miConfig = {
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    }
  };
  return axios
    .post(url, data, miConfig)
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {
      console.log(error);
    });
}
