/* PARA GESTIONAR LOS specialtiesS -  FICHAS */

import { especialidadesService } from "@/_services";

const state = {
  specialtiesAll: {
    loading: false,
    data: null
  },
  specialtiesById: {
    loading: false,
    data: null
  },
  specialtiesCreate: {
    loading: false,
    data: null
  },
  specialtiesUpdate: {
    loading: false,
    data: null
  }
};
const mutations = {
  SET_SPECIALTIES_ALL: (state, value) => {
    state.specialtiesAll = value;
  },

  SET_SPECIALTIES_BYID: (state, value) => {
    state.specialtiesById = value;
  },

  SET_SPECIALTIES_CREATE: (state, value) => {
    state.specialtiesCreate = value;
  },

  SET_SPECIALTIES_UPDATE: (state, value) => {
    state.specialtiesUpdate = value;
  }
};
const actions = {
  /* RECUPERAR LA LISTA DE LOS REGISTROS */
  setSpecialtiesAll({ commit }) {
    const promesa = new Promise((resolve, reject) => {
      /*    if (state.recordAll.data !== null) {
        return resolve(state.recordAll.data);
      } */
      especialidadesService.specialtiesAll().then(res => {
        commit("SET_SPECIALTIES_ALL", res.data);
        return resolve(res.data);
      });
    });

    return promesa;
  },
  /* RECUPERA UN REGISTRO PARTICULAR POR SU ID */
  setSpecialtiesById({ commit, state }, id) {
    const promesa = new Promise((resolve, reject) => {
      especialidadesService.specialtiesById(id).then(res => {
        commit("SET_SPECIALTIES_BYID", res.data);
        return resolve(res.data);
      });
    });
    return promesa;
  },

  /* CREAR UN NUEVO REGISTRO */
  setSpecialtiesCreate({ commit }, data) {},

  /* ACTUALIZAR UN REGISTRO EXISTENTE */
  setSpecialtiesUpdate({ commit }, data) {}
};
const getters = {
  /* LISTA TODOS LOS REGISTROS */
  specialtiesAll: state => {
    return state.SpecialtiesAll;
  },
  specialtiesById: state => {
    return state.specialtiesById;
  }
};

export const especialidades = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
