import {
    agrupSvr
} from "@/_services";
import {
    cloneDeep
} from "lodash";
const state = {
    stAgrup: null,
    stAgrupById: null,   
    stTipoGuia: null,  
};
const mutations = {

    /* funcion para setear los datos de guiasAll */
    SET_AGRUP_ALL: (state, value) => {
        state.stAgrup = value.datos;       
        state.stTipoGuia = value.tipoGuia;     
    },

    /* function para setear una agrup */
    SET_AGRUP_BYID: (state, value) => {
        state.stAgrupById = value.datos;       
        state.stTipoGuia = value.tipoGuia;      
    },

};
const actions = {

    /* funcion para recoger todas las agrup */
    async setAgrupAll({
        commit
    }) {
        const salida = await agrupSvr.agrupAll();
        commit("SET_AGRUP_ALL", salida.data);
        return salida.data;
    },

    /* funcion para recuperar una agrup */
    async setAgrupById({
        commit
    }, id) {
        const salida = await agrupSvr.agrupById(id);
        commit("SET_AGRUP_BYID", salida.data);
        return salida.data;
    },

    /*  funcion para actualizar una agrup */
    async setAgrupUpdate({
        commit
    }, data) {
        const {
            datos,
            id
        } = data;
        const salida = await agrupSvr.agrupUpdate(datos, id);
        return salida.data;

    },

    /* funcion para recoger los datos de una agrup al entrar en el form ,
        tabla de provincias_ccmm, los zip_codes y tipo_guia
    */
    async setDatosAgrup() {
        const salida = await agrupSvr.datosAgrup();
        return salida.data;
    },

    /* funcion para guardar una nueva agrup */
    async setNuevaAgrup({
        commit
    }, datos) {
        const salida = await agrupSvr.nuevaAgrup(datos);
        return salida.data;
    }


};
const getters = {
    gtrAgrupAll: state => cloneDeep(state.stAgrup),
    gtrAgrupById: state => cloneDeep(state.stAgrupById),    
    gtrTipoGuia: state => cloneDeep(state.stTipoGuia),
    
};

export const agrup = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};