
import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import {
  store
} from "./_store";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import axios from "axios";
//import axios from "axios";
window.axios = axios;
// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // Do something with response error
    if (
      error.request.status === 401 &&
      error.request.statusText === "Unauthorized"
    ) {
      // console.log("no estas autorizado");
      localStorage.removeItem(process.env.VUE_APP_LOCALST);
      location.reload();
    }
    return Promise.reject(error);
  }
);

/* añadimos globalmente el compontente de autocomplete */
import DisableAutocomplete from "@aacassandra/vue-disable-autocomplete";
Vue.use(DisableAutocomplete);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");