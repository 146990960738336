import { config } from "@/_config";
import { authHeader } from "@/_helpers";

// import axios from "axios";

export const cateSvr = {
  cateAll,
  cateById,
  cateCreate,
  cateUpdate,
  cateDelete
};

const enlace = "cate";

function cateAll() {
  const url = `${config.apiUrl}/${enlace}`;
  const miConfig = {
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    }
  };

  return axios
    .get(url, miConfig)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
}

function cateById(id) {
  const url = `${config.apiUrl}/${enlace}/${id}`;
  const miConfig = {
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    }
  };

  return axios
    .get(url, miConfig)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
}

function cateCreate(datos) {
  const url = `${config.apiUrl}/${enlace}`;
  const miConfig = {
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    }
  };

  return axios
    .post(url, datos, miConfig)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
}

function cateUpdate(id, datos) {
  const url = `${config.apiUrl}/${enlace}/${id}`;
  const miConfig = {
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    }
  };

  return axios
    .put(url, datos, miConfig)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
}

function cateDelete(id) {
  const url = `${config.apiUrl}/${enlace}/${id}`;

  const miConfig = {
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    }
  };

  return axios
    .delete(url, miConfig)
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {
      return error;
    });
}
