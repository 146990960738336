import { config } from "@/_config";

// import axios from "axios";
import { handleResponse, authHeader } from "../_helpers";

export const especialidadesService = {
  specialtiesAll,
  specialtiesById,
  specialtiesCreate,
  specialtiesUpdate
};

function specialtiesAll() {
  const url = `${config.apiUrl}/categorias`;
  const miConfig = {
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    }
  };

  //return fetch(url, { miConfig }).then(handleResponse);

  return axios
    .get(url, miConfig)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      if (error.response) {
        // Request made and server responded
        console.log(error.response.data);
        console.log(error.response);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
    });
}

function specialtiesById(id) {
  const url = `${config.apiUrl}/categorias/${id}`;
  const miConfig = {
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    }
  };

  return axios
    .get(url, miConfig)
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {
      // console.log(error);
      return error;
    });
}

//body: JSON.stringify(datos)

function specialtiesCreate(data) {
  const url = `${config.apiUrl}/setresult`;
  params.append("id", id);
  params.append("resultado", resultado);
  //console.log(params);
  const miConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      body: JSON.stringify(data),
      ...authHeader()
    }
  };
  return axios
    .post(url, miConfig)
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {
      console.log(error);
    });
}

function specialtiesUpdate(id, data) {
  const url = `${config.apiUrl}/setresult`;
  //params.append("id", id);
  //params.append("resultado", resultado);
  //console.log(params);
  const miConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      body: JSON.stringify(data),
      ...authHeader()
    }
  };
  console.log(miConfig);
  return axios
    .post(url, miConfig)
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {
      console.log(error);
    });
}
