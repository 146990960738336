import {
  config
} from "@/_config";

//import axios from "axios";

import {
  authHeader
} from "@/_helpers";
import {
  handleResponse
} from "@/_helpers";

export const guiasSvr = {
  guiasAll,
  guiaById,
  guiaUpdate,
  datosGuias,
  nuevaGuia,
  guiaPdf
};

/* funcion para solicitar todas las guias */
function guiasAll() {  
  const url = `${config.apiUrl}/guias`;
  const miConfig = {
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    }
  };

  return axios
    .get(url, miConfig)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      console.log("error", err);
      return handleResponse(err);
    });
}

/* funcion para solicitar una guia por su id */
function guiaById(id) {
  const url = `${config.apiUrl}/guias/${id}`;
  const miConfig = {
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    }
  };
  return axios
    .get(url, miConfig)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
}
/* funcion para actualizar una guia */
function guiaUpdate(datos, id) {
  const url = `${config.apiUrl}/guias/${id}`;
  const miConfig = {
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    }
  };
  return axios
    .put(url, datos, miConfig)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
}

/* funcion para solicitar los datos necesarios para gestionar una guia */
function datosGuias() {
  const url = `${config.apiUrl}/datosGuias`;
  const miConfig = {
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    }
  };

  return axios
    .get(url, miConfig)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
}

/* funcion para dar de alta una nueva guia */
function nuevaGuia(data) {
  const url = `${config.apiUrl}/guias`;
  const miConfig = {
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    }
  };
  return axios
    .post(url, data, miConfig)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    });
}

/* funcion para descargar una guia pdf */
/* funcion para solicitar los datos necesarios para gestionar una guia */
function guiaPdf(id) {
  const url = `${config.apiUrl}/pdfGuia/${id}`;
  // console.log("ruta", url);
  const data = {};
  const miConfig = {
    headers: {
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/pdf",
        ...authHeader()
      }
    }
  };
  return axios
    .post(url, data, miConfig.headers)
    .then(res => {
      // console.log(res);
      return res;
    })
    .catch(err => {
      return err;
    });
}
