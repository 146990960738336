import { config } from "@/_config";
import { authHeader } from "@/_helpers";

// import axios from "axios";

export const espeSvr = {
  espeAll,
  espeById,
  espeCreate,
  espeUpdate,
  espeDelete
};

const enlace = "especialidades";

function espeAll() {
  const url = `${config.apiUrl}/${enlace}`;
  const miConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      ...authHeader()
    }
  };

  return axios
    .get(url, miConfig.headers)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
}

function espeById(id) {
  const url = `${config.apiUrl}/${enlace}/${id}`;
  const miConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      ...authHeader()
    }
  };

  return axios
    .get(url, miConfig.headers)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
}

function espeCreate(datos) {
  const url = `${config.apiUrl}/${enlace}`;
  const miConfig = {
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    }
  };

  return axios
    .post(url, datos, miConfig)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
}

function espeUpdate(id, datos) {
  const url = `${config.apiUrl}/${enlace}/${id}`;
  const miConfig = {
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    }
  };
  return axios
    .put(url, datos, miConfig)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
}

function espeDelete(id) {
  const url = `${config.apiUrl}/${enlace}/${id}`;

  const miConfig = {
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    }
  };

  return axios
    .delete(url, miConfig)
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {
      return error;
    });
}

function espeMove(id, idCategory) {

}
